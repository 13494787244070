.tab {
    color: dimgrey;
    text-align: center;
    white-space: nowrap;
    padding: 0px 4px;
    font-size: 16px;
    margin: 8px 12px;
}

.active-tab > .tab {
    color: black;
    font-weight: bold;
    border-bottom: 4px solid #f46f46;
}

